import React, { useMemo, useState } from "react";
import { TawreedForm, TawreedFormSubmitProps } from "../../../../../../common/components/form";
import { OrderDto, OrderItemDto, orderService as service } from "../../../domain";
import { OrderFormItems } from "../order-form-items";
import { GlobalContext } from "../../../../../../context";
import { TawreedAction, TawreedActionName } from "../../../../../../common/actions";
import { PrimeIcons } from "primereact/api";
import { OrderFormSummary } from "../order-form-summary";
import { OrderDocumentTable } from "../order-document-table";
import * as actions from "./actions";
import { OrderFormProps } from "./order-form.props";
import { OrderFormState } from "./order-form.state";
import { OrderFormInformation1 } from "./order-form-information-1";
import './order-form.scss';
import { AuthService, authService } from "../../../../../auth/domain";
import { PackagesDialog } from "./order-packages-popup";
import { tawreedTranslationService } from "../../../../../../common/translations";
import { Panel } from "primereact/panel";
import { FileService, TawreedFile } from "../../../../../upload";
import { OrderDeliverDocumentUploadDialog } from "./order-document-upload-dialog";
import { classNames } from "primereact/utils";
import { OrderNote } from "./order-note";
import { OrderNoteDialog } from "./order-note-dialog";
import { OrderStatus } from "../../../data";
import { OrderpackagesFilesDialog } from "./order-package-files-popup";
import { ShipmentPackage } from "../../../domain/dtos/ready-to-delivery";
import { useHistory } from "react-router-dom";

export const OrderForm: React.FC<OrderFormProps> = ({ listener, orderId, returned, showBack }) => {

    // refs
    const confirmDialog = React.useRef<actions.OrderConfirmDialog>(null);
    const cancelDialog = React.useRef<actions.OrderCancelDialog>(null);
    const returnDialog = React.useRef<actions.OrderReturnDialog>(null);
    const discountDialog = React.useRef<actions.OrderApplyDiscountDialog>(null);

    const userService: AuthService = useMemo(() => new AuthService(), []);
    const fileService: FileService = React.useMemo(() => new FileService(), []);

    const history = useHistory();
    // di
    const { constants: { constants }, auth: { user } } = React.useContext(GlobalContext);
    // states
    const [state, setState] = React.useState<OrderFormState>({
        loading: false,
        mode: 'Edit',
        form: {
            //
        },
        actions: [],
    });
    const [otpPopUp, setOtpPopUp] = useState({
        isVisable: false,
        otpCode: ''
    });

    const [reload, setReload] = useState(false);

    const [packagesPopUp, setPackagesPopUp] = useState({
        isVisable: false,
        orderStatus: 0,
        caller: ""
    });

    const [packagesFilesPopUp, setPackagesFilesPopUp] = useState({
        isVisable: false,
        caller: ""
    });

    const [deliverDocumentPopUp, setDeliverDocumentPopUp] = useState({
        isVisable: false,
        caller: ""
    });

    const [orderNotePopUp, setOrderNotePopUp] = useState({
        isVisable: false
    });

    React.useEffect(() => {
        const next = new Array<TawreedAction>();
        if (state.form.showConfirm) {
            const onConfirm = (_sender: any, _params: any, carrierId: number): Promise<void> => {
                return actions
                    .onConfirm(state.form.orderId!, carrierId, listener)
                    .then(res => {
                        if (res) {
                            // setState(previous => {
                            //     return { ...previous, form: res }
                            // })
                            history.goBack();
                        }
                    });
            }
            next.push(new TawreedAction(TawreedActionName.OrdersConfirm, 'statefull', 'lbl_sales_orders_confirm', PrimeIcons.CHECK, onConfirm, undefined, { mode: 'advanced', ref: confirmDialog }));
        }
        if (state.form.showReadyForDelivery) {
            const onReady = () => {
                return new Promise<void>(() => {
                    setPackagesPopUp({ isVisable: true, orderStatus : state.form.statusId!, caller: "readyForDelivery"});
                });
            }
            next.push(new TawreedAction(TawreedActionName.OrdersReadyForDelivery, 'statefull', 'lbl_sales_orders_ready', PrimeIcons.CHECK, onReady, undefined, false));
        }

        if (state.form.showShip) {
            const onShip = () => {
                if(state.form.statusId === OrderStatus.READY_FOR_DELIVERY){
                    return actions
                        .onShip(state.form.orderId!, listener)
                        .then(res => {
                            if (res) {
                                // setState(previous => {
                                //     return { ...previous, form: res }
                                // })
                                history.goBack();
                            }
                        });
                }
                else{
                    return new Promise<void>(() => {
                        setPackagesPopUp({ isVisable: true, orderStatus : state.form.statusId!, caller: "ship"});
                    });
                }
            };
            next.push(new TawreedAction(TawreedActionName.OrdersShip, 'statefull', 'lbl_sales_orders_ship', PrimeIcons.SEND, onShip, undefined, state.form.statusId === OrderStatus.READY_FOR_DELIVERY ? true : false));
        }

        if (state.form.showDeliver) {
            const onDeliver = () => {
                if (state.form.payThroughUs && state.form.requiredOtp) {

                    return userService.sendOtp({ type: 2, key: state.form.customer?.contact?.mobileNo?.substring(2) || '' }).then(res => {
                        //switch
                        switch(state.form.statusId){
                            case OrderStatus.Placed:
                                setPackagesFilesPopUp({isVisable: true, caller: "placed"});
                                break;
                            case OrderStatus.Processing:
                                setPackagesFilesPopUp({isVisable: true, caller: "processing"})
                                break;
                            case OrderStatus.READY_FOR_DELIVERY:
                                setDeliverDocumentPopUp({ isVisable: true, caller: "ready" });
                                break;
                            case OrderStatus.Shipped:
                                setDeliverDocumentPopUp({ isVisable: true, caller: "ship" });
                                break;
                        }
                        
                    });
                }
                else {
                    return new Promise<void>(() => {
                        switch(state.form.statusId){
                            case OrderStatus.Placed:
                                setPackagesFilesPopUp({isVisable: true, caller: "placed"});
                                break;
                            case OrderStatus.Processing:
                                setPackagesFilesPopUp({isVisable: true, caller: "processing"})
                                break;
                            case OrderStatus.READY_FOR_DELIVERY:
                                setDeliverDocumentPopUp({ isVisable: true, caller: "ready" });
                                break;
                            case OrderStatus.Shipped:
                                setDeliverDocumentPopUp({ isVisable: true, caller: "ship" });
                                break;
                        }
                    });
                }
            };
            next.push(new TawreedAction(TawreedActionName.OrdersDeliver, 'statefull', 'lbl_sales_orders_deliver', PrimeIcons.TABLE, onDeliver, undefined, false));
        }

        if (state.form.showReturn) {
            const onReturn = (_sender: any, _params: any, cancelReason: any): Promise<void> => {
                return actions
                    .onReturn(returned, state.form.orderId!,
                        cancelReason.reason, cancelReason.reasonAr, cancelReason.reasonEn, listener)
                    .then(res => {
                        if (res) {
                            // setState(previous => {
                            //     return { ...previous, form: res }
                            // })
                            history.goBack();
                        }
                    });
            };
            next.push(new TawreedAction(TawreedActionName.OrdersReturn, 'statefull', 'lbl_sales_orders_return', PrimeIcons.REPLY, onReturn, undefined, { mode: 'advanced', ref: returnDialog }));
        }

        if (state.form.showCancel) {
            if (returned) {
                const onCancel = (): Promise<void> => {
                    return actions
                        .onReturnCancel(state.form.orderId!, listener)
                        .then(res => {
                            if (res) {
                                // setState(previous => {
                                //     return { ...previous, form: res }
                                // })
                                history.goBack();
                            }
                        });
                }
                next.push(new TawreedAction(TawreedActionName.OrdersCancel, 'statefull', 'lbl_sales_orders_cancel', PrimeIcons.TIMES, onCancel, undefined, true));
            } else {
                const onCancel = (_sender: any, _params: any, cancelReasonId: number): Promise<void> => {
                    return actions
                        .onCancel(state.form.orderId!, cancelReasonId, listener)
                        .then(res => {
                            if (res) {
                                // setState(previous => {
                                //     return { ...previous, form: res }
                                // })
                                history.goBack();
                            }
                        });
                }
                next.push(new TawreedAction(TawreedActionName.OrdersCancel, 'statefull', 'lbl_sales_orders_cancel', PrimeIcons.TIMES, onCancel, undefined, { mode: 'advanced', ref: cancelDialog }));

            }
        }

        if (state.form.statusId) {
            const onPrint = (report: string) => {
                return service.print(report)
                    .then(() => {
                    })
                    .catch(err => console.error(err));
            };

            if (state.form.commissionReportURI) {
                next.push(new TawreedAction(TawreedActionName.OrdersPrintStore, 'statefull', 'lbl_sales_orders_store_print', PrimeIcons.PRINT, () => onPrint(state.form.commissionReportURI!), undefined, false));
            }
            if (state.form.orderReportURI) {
                next.push(new TawreedAction(TawreedActionName.OrdersPrintCustomer, 'statefull', 'lbl_sales_orders_customer_print', PrimeIcons.PRINT, () => onPrint(state.form.orderReportURI!), undefined, false));
            }
            if (state.form.shipmentOrderReportURI) {
                next.push(new TawreedAction(TawreedActionName.OrdersPrintCustomer, 'statefull', 'lbl_sales_orders_shipment_print', PrimeIcons.PRINT, () => onPrint(state.form.shipmentOrderReportURI!), undefined, false));
            }
            if(state.form.orderItemsReportURI){
                next.push(new TawreedAction(TawreedActionName.OrdersPrintCustomer, 'statefull', 'lbl_sales_orders_items', PrimeIcons.PRINT, () => onPrint(state.form.orderItemsReportURI!), undefined, false));
            }
        }
        setState(previous => {
            return { ...previous, actions: next }
        });
        // const onApplyDiscount = (_sender: any, _params: any, discountInfo: any): Promise<void> => {
        //     return actions
        //         .onApplyDiscount(state.form.orderId!, discountInfo.discountValue, discountInfo.discountType, listener)
        //         .then(res => {
        //             if (res) {
        //                 setState(previous => {
        //                     return { ...previous, form: res }
        //                 })
        //             }
        //         });
        // }
        // next.push(new TawreedAction(TawreedActionName.OrderApplyDiscount, 'statefull', 'lbl_sales_orders_applay_discount', PrimeIcons.CHECK, onApplyDiscount , undefined, { mode: 'advanced', ref: discountDialog }));
        // // eslint-disable-next-line
    }, [state.form, returned, listener]);

const onApplyDiscount = (discountInfo: any): Promise<void> => {
    setState({...state, loading: true});
            return actions
                .onApplyDiscount(state.form.orderId!, discountInfo.discountValue, discountInfo.discountType, listener)
                .then(res => {
                    if (res) {
                        setState(previous => {
                            return { ...previous, form: res, loading:false }
                        })
                    }
                    else{
                        setState({...state, loading: false});
                    }
                }).catch(()=>{
                    setState({...state, loading: false});
                });
        }

    React.useEffect(() => {
        let mounted = true;
        if (orderId) {
            if (mounted) {
                setState(previous => {
                    return { ...previous, loading: true, mode: 'Edit' }
                });
                service.getDetails(orderId, returned)
                    .then(res => {
                        setState(previous => {
                            return { ...previous, loading: false, form: res }
                        });
                    })
                    .catch(() => {
                        setState(previous => {
                            return { ...previous, loading: false }
                        });
                    });
            }
        } else {
            if (mounted) {
                setState(previous => {
                    return { ...previous, loading: false, mode: 'Create', form: {} }
                });
            }
        }
        return () => {
            mounted = false;
        };
    }, [orderId, returned]);

    const submit: TawreedFormSubmitProps<OrderDto> = {
        showButton: false,
        resetOnSubmit: false,
    }

    const onSaveItem = (item: OrderItemDto) => {
        setState(previous => {
            return { ...previous, loading: true }
        });
        setReload(false);
        return service.updateItem(item)
            .then(res => {
                setState({ ...state, form: res, loading: false });
                if (listener) {
                    listener({ type: 'item-changes', sender: res });
                }
                setReload(true);
                return true;
            })
            .catch(() => {
                setState({ ...state, loading: false });
                return false;
            });
    }
    const addOrderFile = (files: Array<TawreedFile>, title: string, type: string) => {
        setState({ ...state, loading: true });
        service.createFile(files[0], files, title, orderId!, type).then((re) => {
            service.getDetails(orderId!, returned)
                .then(res => {
                    console.log("getdetails", res);
                    setState(previous => {
                        return { ...previous, loading: false, form: res }
                    });
                })
                .catch(() => {
                    setState(previous => {
                        return { ...previous, loading: false }
                    });
                });

        }).catch(() => {
            setState({ ...state, loading: false });

        });
    }
    const downloadOrderFile = async (fileId: string, name?: string) => {
        try {
            setState({ ...state, loading: true });

            await fileService.downloadS3(fileId, name);

        } catch (err) {
            console.log(err);
        }
        setState({ ...state, loading: false });

    }

    const onRequestCarrierChange = (): void => {
        const onCarrierChange = (_sender: any, _params: any, carrierId: number): Promise<void> => {
            return actions
                .onCarrierChange(state.form.orderId!, carrierId, listener)
                .then(res => {
                    if (res) {
                        // setState(previous => {
                        //     return { ...previous, form: res }
                        // })
                        history.goBack();
                    }
                });
        }
        const action = new TawreedAction(TawreedActionName.OrdersConfirm, 'statefull', 'lbl_sales_orders_carrier_change', PrimeIcons.CHECK, onCarrierChange, undefined, { mode: 'advanced', ref: confirmDialog });
        action.execute().then();
    }

    const accept = (data: { files: Array<string> | undefined, code: string | undefined }) => {
        setState({ ...state, loading: true });
        var caller = deliverDocumentPopUp.caller;
        setDeliverDocumentPopUp({ isVisable: false, caller:"" })
        if (state.form.payThroughUs && state.form.requiredOtp) {
            return authService.veifyOtp({ type: 2, key: state.form.customer?.contact?.mobileNo?.substring(2) || '', code: +data.code! })
                .then(res => {
                    if(caller==="ready")
                    {
                        return actions
                        .onShipAndDeliver({
                            orderId: state.form.orderId!,
                            otp: res.data,
                            files: data.files ?? []
                        }, listener)
                        .then(res => {
                            if (res) {
                                // setState(previous => {
                                //     return { ...previous, loading: false, form: res }
                                // });
                                history.goBack();
                            }
                            else {
                                setState(previous => {
                                    return { ...previous, loading: false }
                                });
                            }
                        }).catch(() => {
                            setState(previous => {
                                return { ...previous, loading: false }
                            });
                        });
                    }
                    else{
                        return actions
                        .onDeliver({
                            orderId: state.form.orderId!,
                            otp: res.data,
                            files: data.files ?? []
                        }, listener)
                        .then(res => {
                            if (res) {
                                // setState(previous => {
                                //     return { ...previous, loading: false, form: res }
                                // });
                                history.goBack();
                            }
                            else {
                                setState(previous => {
                                    return { ...previous, loading: false }
                                });
                            }
                        }).catch(() => {
                            setState(previous => {
                                return { ...previous, loading: false }
                            });
                        });
                    }
                   
                });

        }
        else {
            if(caller==="ready"){
            return actions
                .onShipAndDeliver({ orderId: state.form.orderId!, files: data.files ?? [] }, listener)
                .then(res => {
                    if (res) {
                        // setState(previous => {

                        //     return { ...previous, loading: false, form: res }
                        // });
                        history.goBack();
                    }
                    else {
                        setState(previous => {
                            return { ...previous, loading: false }
                        });
                    }
                }).catch(() => {
                    setState(previous => {
                        return { ...previous, loading: false }
                    });
                });
            }
            else{
                return actions
                .onDeliver({ orderId: state.form.orderId!, files: data.files ?? [] }, listener)
                .then(res => {
                    if (res) {
                        // setState(previous => {

                        //     return { ...previous, loading: false, form: res }
                        // });
                        history.goBack();
                    }
                    else {
                        setState(previous => {
                            return { ...previous, loading: false }
                        });
                    }
                }).catch(() => {
                    setState(previous => {
                        return { ...previous, loading: false }
                    });
                });
            }
        }

    }


    const acceptPackages = (data: {shipmentPackages:ShipmentPackage[] }) => {
        
        var caller = packagesPopUp.caller;
        setPackagesPopUp({ ...packagesPopUp ,isVisable: false });
        if(state.form.statusId == OrderStatus.Placed){
            switch(caller){
                case "readyForDelivery":
                return actions.onConfirmAndReady(state.form.orderId!, state.form.store?.partnerId!, data.shipmentPackages, listener)
                .then(res => {
                    if (res) {
                        // setState(previous => {
                        //     return { ...previous, loading: false, form: res }
                        // });
                        history.goBack();
                    }
                    else {
                        setState(previous => {
                            return { ...previous, loading: false }
                        });
                    }
                }).catch(() => {
                    setState(previous => {
                        return { ...previous, loading: false }
                    });
                });
                case "ship":
                        return actions.onConfirmReadyAndShip(state.form.orderId!, state.form.store?.partnerId!, data.shipmentPackages, listener)
                        .then(res => {
                            if (res) {
                                // setState(previous => {
                                //     return { ...previous, loading: false, form: res }
                                // });
                                history.goBack();
                            }
                            else {
                                setState(previous => {
                                    return { ...previous, loading: false }
                                });
                            }
                        }).catch(() => {
                            setState(previous => {
                                return { ...previous, loading: false }
                            });
                        });
            }
        }
        else{
            if(state.form.statusId == OrderStatus.Processing){
                switch(caller){
                    case "readyForDelivery":
                        return actions.onReady(state.form.orderId!,data.shipmentPackages, listener)
                        .then(res => {
                            if (res) {
                                // setState(previous => {
                                //     return { ...previous, loading: false, form: res }
                                // });
                                history.goBack();
                            }
                            else {
                                setState(previous => {
                                    return { ...previous, loading: false }
                                });
                            }
                        }).catch(() => {
                            setState(previous => {
                                return { ...previous, loading: false }
                            });
                        });
                    case "ship":
                            return actions.onReadyAndShip(state.form.orderId!, data.shipmentPackages, listener)
                            .then(res => {
                                if (res) {
                                    // setState(previous => {
                                    //     return { ...previous, loading: false, form: res }
                                    // });
                                    history.goBack();
                                }
                                else {
                                    setState(previous => {
                                        return { ...previous, loading: false }
                                    });
                                }
                            }).catch(() => {
                                setState(previous => {
                                    return { ...previous, loading: false }
                                });
                            });
                }
            }
            
        }
        
    }


    const acceptPackagesAndFiles = (data: { files: Array<string> | undefined, 
        code: string | undefined
        , shipmentPackages:ShipmentPackage[] }) => {

        setState({ ...state, loading: true });
        var caller = packagesFilesPopUp.caller;
        setPackagesFilesPopUp({ ...packagesFilesPopUp ,isVisable: false });
        if (state.form.payThroughUs && state.form.requiredOtp) {
            return authService.veifyOtp({ type: 2, key: state.form.customer?.contact?.mobileNo?.substring(2) || '', code: +data.code! })
                .then(res => {
                    if(caller =="placed"){
                    return actions
                        .onConfirmReadyShipAndDeliver({ orderId: state.form.orderId!,
                            carrierId: state.form.store?.partnerId!,
                            shipmentPackageDtoList: data.shipmentPackages,
                            otp: res.data,
                            files: data.files ?? [] }, listener)
                        .then(res => {
                            if (res) {
                                // setState(previous => {
                                //     return { ...previous, loading: false, form: res }
                                // });
                                history.goBack();
                            }
                            else {
                                setState(previous => {
                                    return { ...previous, loading: false }
                                });
                            }
                        }).catch(() => {
                            setState(previous => {
                                return { ...previous, loading: false }
                            });
                        });
                    }
                else{
                    return actions
                        .onReadyShipAndDeliver({ orderId: state.form.orderId!,
                            carrierId: state.form.store?.partnerId!,
                            shipmentPackageDtoList: data.shipmentPackages,
                            otp: res.data,
                            files: data.files ?? [] }, listener)
                        .then(res => {
                            if (res) {
                                // setState(previous => {
                                //     return { ...previous, loading: false, form: res }
                                // });
                                history.goBack();
                            }
                            else {
                                setState(previous => {
                                    return { ...previous, loading: false }
                                });
                            }
                        }).catch(() => {
                            setState(previous => {
                                return { ...previous, loading: false }
                            });
                        });
                }
                    
                });

        }
        else {
            if(caller =="placed"){
                return actions
                .onConfirmReadyShipAndDeliver({ orderId: state.form.orderId!,
                    carrierId: state.form.store?.partnerId!,
                    shipmentPackageDtoList: data.shipmentPackages,
                    files: data.files ?? [] }, listener)
                .then(res => {
                    if (res) {
                        // setState(previous => {
                        //     return { ...previous, loading: false, form: res }
                        // });
                        history.goBack();
                    }
                    else {
                        setState(previous => {
                            return { ...previous, loading: false }
                        });
                    }
                }).catch(() => {
                    setState(previous => {
                        return { ...previous, loading: false }
                    });
                });
            }
            else{
                return actions
                .onReadyShipAndDeliver({ orderId: state.form.orderId!,
                    
                    carrierId: state.form.store?.partnerId!,
                    shipmentPackageDtoList: data.shipmentPackages,
                    files: data.files ?? [] }, listener)
                .then(res => {
                    if (res) {
                        // setState(previous => {

                        //     return { ...previous, loading: false, form: res }
                        // });
                        history.goBack();
                    }
                    else {
                        setState(previous => {
                            return { ...previous, loading: false }
                        });
                    }
                }).catch(() => {
                    setState(previous => {
                        return { ...previous, loading: false }
                    });
                });
            }
            
        }

    }
    
    const acceptNote = (note: string | undefined) => {
        setState({ ...state, loading: true });
        setOrderNotePopUp({ isVisable: false })
        service.addNote({ orderId: state.form.orderId, note: note })
            .then(res => {
                if (res) {
                    setState(previous => {
                        return { ...previous, loading: false, form: res }
                    });
                }
                else {
                    setState(previous => {
                        return { ...previous, loading: false }
                    });
                }
            }).catch(() => {
                setState(previous => {
                    return { ...previous, loading: false }
                });
            });

    }

    return (
        <React.Fragment>
            <TawreedForm title={returned ? 'lbl_sales_returned_orders' : 'lbl_sales_orders'}
                dataKey="orderId" data={state.form}
                submit={submit}
                loading={state.loading} mode={state.mode} actions={state.actions} useBack={showBack ? '..' : undefined} className="grid form-grid">

                <React.Fragment>
                    <actions.OrderConfirmDialog ref={confirmDialog}
                        salesorderId={orderId} payThroughUs={state.form.payThroughUs} partner={state.form.store} carrier={state.form?.carrier ? state.form.carrier.partnerId : undefined} customer={state.form.customer ?? undefined} />
                    <actions.OrderCancelDialog ref={cancelDialog} />
                    <actions.OrderReturnDialog ref={returnDialog} />
                    <actions.OrderApplyDiscountDialog ref={discountDialog} invoiceDiscountType={state.form.invoiceDiscountType} invoiceDiscountValue={state.form.invoiceDiscountValue} />
                </React.Fragment>

                {
                    user &&

                    <div className="col-12">
                        <OrderFormInformation1 salesOfferTitle={state.form.salesOfferTitle} className="" returned={returned} orderStatuses={constants?.orderStatuses} salesOrderId={state.form.salesOrderId} showShipment={state.form.showShipment} editShipment={state.form.editShipment} requestCarrierChange={() => onRequestCarrierChange()} />
                    </div>
                }

                <OrderFormItems
                    className="col-12"
                    returned={returned}
                    showEdit={state.form.showEdit}
                    orderId={orderId}
                    reload={reload}
                    showNote={state.form.showConfirm || false}
                    onSave={onSaveItem} />

                <OrderFormSummary className="col-12"
                    returned={returned}
                    showInvoiceDiscount= {state.form.showInvoiceDiscount}
                    orderStatus={state.form.statusId}
                    onApplyDiscount={onApplyDiscount}
                    subTotal={state.form.subTotal}
                    shippingTotal={state.form.shippingTotal}
                    discount={state.form.totalDiscount}
                    total={state.form.total}
                    totalTax={state.form.totalTax}
                    grossTotal={state.form.grossTotal}
                    invoiceTotalDiscount={state.form.invoiceTotalDiscount}
                    invoiceDiscountType={state.form.invoiceDiscountType}
                    invoiceDiscountValue={state.form.invoiceDiscountValue}
                />


            </TawreedForm>
            <PackagesDialog
                visible={packagesPopUp.isVisable}
                orderId={state.form.orderId!}
                titles={state.form.statusId === OrderStatus.Processing ? [tawreedTranslationService.translate('lbl_readyToDeliver')]:
                    [tawreedTranslationService.translate('lbl_readyToDeliver'), tawreedTranslationService.translate('lbl_readyToDeliverNote')]}
                accept={acceptPackages}
                hide={() => {
                    setPackagesPopUp({ isVisable: false, orderStatus:0, caller:"" })
                }}
            />
            <OrderDeliverDocumentUploadDialog
                visible={deliverDocumentPopUp.isVisable}
                accept={accept}
                requireOtp={state.form.payThroughUs! && state.form.requiredOtp!}
                payThroughUs={state.form.payThroughUs!}
                hide={() => {
                    setDeliverDocumentPopUp({ isVisable: false, caller:"" })
                }} />
            <OrderpackagesFilesDialog
                visible={packagesFilesPopUp.isVisable}
                caller={packagesFilesPopUp.caller}
                carrierId={state.form.store?.partnerId!}
                listener={listener}
                orderId={state.form.orderId!}
                requireOtp={state.form.payThroughUs! && state.form.requiredOtp!}
            //    payThroughUs={state.form.payThroughUs!}
            hide={() => {
                setPackagesFilesPopUp({ isVisable: false, caller:"" })
            }}
            accept={acceptPackagesAndFiles}
             />
            <OrderNoteDialog
                visible={orderNotePopUp.isVisable}
                accept={acceptNote}
                hide={() => {
                    setOrderNotePopUp({ isVisable: false });
                }}
            />
            <Panel header={tawreedTranslationService.translate('lbl_order_notes')} className="col-12"

                icons={
                    <React.Fragment>
                        <div className="formgroup-inline">
                            <i className={classNames(PrimeIcons.PLUS, 'bouns_table_plus')} onClick={() => setOrderNotePopUp({ isVisable: true })}></i>
                        </div>
                    </React.Fragment>
                }
            >
                {
                    state.form.notes?.map((note) => {
                        return <React.Fragment key={note.salesOrderNoteId}>
                            <OrderNote salesOrderNoteId={note.salesOrderNoteId}
                                userName={note.userName} note={note.note} createdAt={note.createdAt}
                                partner={note.partner} email={note.email} mobileNo={note.mobileNo}
                                salesOrderId={note.salesOrderId} userId={note.userId}></OrderNote>
                            <br />
                        </React.Fragment>
                    })
                }
            </Panel>
            <Panel header={tawreedTranslationService.translate('lbl_Doument_files')} className="col-12">
                <OrderDocumentTable data={state.form?.fiels ?? []} addDocumentFile={addOrderFile} downloadDocumentFile={downloadOrderFile} loading={state.loading}></OrderDocumentTable>
            </Panel>

        </React.Fragment>
    );
}
