/***
 *
 */
export enum Routes {
    TawreedError = "/error",
    Login = "/login",
    Notifications = "/notifications",
    Dashboard = "/",
    ProductsSearch = "/catalog/products/",
    ProductsNew = "/catalog/products/new/",
    ProductsDetails = "/catalog/products/:id/",
    StoreProductsSearch = "/catalog/store-products/",
    ImportStoreProductsSearch = "/import/store-products/",
    StoreProductsNew = "/catalog/store-products/new/:pid",
    StoreProductsDetails = "/catalog/store-products/:id/",
    SellerStoreProductSearch = '/catalog/seller-store-products/:id/',

    //unverified products
    UnverifiedProductsSearch = "/catalog/unverfied-products/",
    UnverifiedProductsDetails = "/catalog/unverfied-products/:id/",

    // users 50
    UsersSearch = "/users",
    UsersNew = "/users/new",
    UsersDetails = "/users/:id",
    // partners 47
    PartnersCustomersSearch = "/partners/customers/",
    PartnersCustomersNew = "/partners/customers/new",
    PartnersCustomersDetails = "/partners/customers/:id",
    PartnersCustomersView = "/partners/customer/:id",
    PartnersConnectionView = "/partners/connection/:id",
    PartnersStoresSearch = "/partners/stores/",
    PartnersStoresNew = "/partners/stores/new",
    PartnersStoresDetails = "/partners/stores/:id",
    
    //connections
    PartnerConnectionsSearch = "/connections",

    //payment partner
    PaymentPartnersSearch = "/paymentpartners/",
    PaymentPartnersNew = "/paymentpartners/new",
    PaymentPartnersDetails = "/paymentpartners/:id",
    // payment | transactions
    PaymentTransactionsSearch = "/payment/transactions",
    PaymentTransactionDetails = "/payment/transactions/:id",

    // payment | orders
    PaymentOrdersSearch = "/payment/orders",
    PaymentOrderDetails = "/payment/orders/:id",
    // payment | broken orders
    BrokenOrdersSearch = "/payment/broken-orders",
    BrokenOrderDetails = "/payment/broken-orders/:id",

    // wrong orders
    DeliveredOrdersSearch = "/deliveredOrders",
    ShippedOrdersSearch = "/shippedOrders",
    PlacedOrdersSearch = "/placedOrders",
    WrongOrderDetails = "/wrongOrders/:id",
    //delivery partner
    DeliveryPartnersSearch = "/deliveryPartners/",
    DeliveryPartnersNew = "/deliveryPartners/new",
    DeliveryPartnersDetails = "/deliveryPartners/:id",
    // sales-cart 41
    SalesCartsSearch = "/sales/carts/",
    SalesCartsNew = "/sales/carts/new",
    SalesCartsDetails = "/sales/carts/:id",
    SalesCartNew ="/sales/cart/:id",
    // sales-orders | all
    SalesOrdersSearch = "/sales/orders/",
    // sales-orders | placed 37
    SalesOrdersPlacedSearch = "/sales/orders/placed/",
    SalesOrdersPlacedDetails = "/sales/orders/placed/:id/",
    // sales-orders | processing 35
    SalesOrdersProcessingSearch = "/sales/orders/processing/",
    SalesOrdersProcessingDetails = "/sales/orders/processing/:id/",

    // sales-orders | ready for delivery
    SalesOrdersReadySearch = "/sales/orders/ready/",
    SalesOrdersReadyDetails = "/sales/orders/ready/:id",
    // sales-orders | shipped
    SalesOrdersShippedSearch = "/sales/orders/shipped/",
    SalesOrdersShippedDetails = "/sales/orders/shipped/:id",

    // sales-orders | all-details
    SalesOrdersDetails = "/sales/orders/:id/",
    // sales-orders | public-details
    PublicSalesOrdersDetails = "/order/:lang/:id",
    // sales-orders-returned | all
    SalesOrdersReturnedSearch = "/sales/return-orders/",
    // sales-orders-returned | all
    SalesOrdersReturnedShippedSearch = "/sales/return-orders/shipped",
    SalesOrdersReturnedShippedDetails = "/sales/return-orders/shipped/:id/",
    // sales-orders-returned | all-details 25
    SalesOrdersReturnedNew = "/sales/return-orders/new",
    SalesOrdersReturnedDetails = "/sales/return-orders/:id/",
    // finance
    FinanceDocumentType1 = "/finance/documents/payment-voucher",
    FinanceDocumentType2 = "/finance/documents/payment-receipt",
    FinanceDocumentType3 = "/finance/documents/note-credit",
    FinanceDocumentType4 = "/finance/documents/note-debit",
    FinanceDocumentJournalType = "/finance/documents/journal",

    FinanceDocumentType1Details = "/finance/documents/payment-voucher/:id",
    FinanceDocumentType2Details = "/finance/documents/payment-receipt/:id",
    FinanceDocumentType3Details = "/finance/documents/note-credit/:id",
    FinanceDocumentType4Details = "/finance/documents/note-debit/:id",
    FinanceDocumentJournalTypeDetails = "/finance/documents/journal/:id",
    FinanceDocumentTypeGeneralDetails = "/finance/documents/:id",
    // reports
    ReportsFinancialDashboard = "/reports/financial/dashboard/",
    ReportsFinancialDocuments = "/reports/financial/documents/",
    ReportsFinancialStatementsPerPartner = "/reports/financial/statements-per-partner",
    ReportsFinancialStatementsPerAccount = "/reports/financial/statements-per-account",
    ReportsSalesOrders = "/reports/sales/orders/",
    ReportsSalesReturnedOrders = "/reports/sales/return-orders/",
    ReportsInvoices = "/reports/financial/invoices/",
    ReportsReturnedInvoices = "/reports/financial/return-invoices",
    ReportsTrialBalance = "/reports/financial/trial-balance/",

    // zones | stores
    StoreZonesSearch = '/store-zones/',
    // zones
    ZonesSearch = '/zones/',
    ZonesNew = '/zones/new',
    ZonesDetails = '/zones/:id',

    //
    MarketingNotificationCampaign = '/marketing/campaign',

    // topup | partners
    TopUpPartnersSearch = "/topup/partners",
    TopUpPartnersNew = "/topup/partners/new",
    TopUpPartnersDetails = "/topup/partners/:id",
    // topup | transactions
    TopUpTransactionsSearch = "/topup/transactions",
    TopUpTransactionsDetails = "/topup/transactions/:id",
    // topup | wallet transactions
    WalletTransaction = "/wallet/transaction",
    WalletTransactionDetails = "/wallet/transactions/:id",


    SettingsCategory = "/settings/category",
    SettingsCategoryNew = "/settings/category/new",
    SettingsCategoryDetials = "/settings/category/:id",

    SettingsBanner = "/settings/banner",
    SettingsBannerNew = "/settings/banner/new",
    SettingsBannerDetials = "/settings/banner/:id",
    ImportHelper = "/import/helper",
    ImportHelperDetails = "/import/helper/:id",

    //SalesCartsSearch = "/sales/carts/",
    //SalesCartsNew = "/sales/carts/new",
    // SalesCartsDetails = "/sales/carts/:id",

    //sales offer
    SalesOfferSearch = "/salesOffer",
    SalesOfferNew = "/salesOffer/new/",
    SalesOfferDetails = "/salesOffer/:id/",
}
